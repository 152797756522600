import React from 'react'

import { InfoCardProps } from './__base/types'
import { InfoCardSize } from './__base/constants'
import { InfoCardWrapper } from './__base/InfoCardWrapper'
import { InfoCardBody } from './__base/InfoCardBody'

type Props = InfoCardProps & {
  className?: string
}

export const InfoCard: React.FC<Props> = ({ size = InfoCardSize.s, className, label, value, icon }) => {
  return (
    <InfoCardWrapper className={className} size={size}>
      <InfoCardBody label={label} value={value} icon={icon} />
    </InfoCardWrapper>
  )
}

import React from 'react'

import Typography from 'shared/components/Typography'

import { InfoCardProps } from './types'
import styles from './InfoCard.module.css'

type Props = Pick<InfoCardProps, 'icon' | 'label' | 'value'>

export const InfoCardBody: React.FC<Props> = ({ label, value, icon }) => {
  return (
    <div className={styles.body}>
      <div className={styles.body__icon}>{icon}</div>
      <div className={styles.body__label_value}>
        <Typography className={styles.body__label}>{label}</Typography>
        <Typography size="xl" weight="bold">
          {value}
        </Typography>
      </div>
    </div>
  )
}

import React from 'react'

import { InfoCardSize } from './__base/constants'
import { InfoCardBody } from './__base/InfoCardBody'
import { InfoCardDivider } from './__base/InfoCardDivider'
import { InfoCardWrapper } from './__base/InfoCardWrapper'
import { CardListProps } from './__base/types'

type Props = CardListProps & {
  className?: string
}

export const InfoCardList: React.FC<Props> = ({ data, className, size = InfoCardSize.s }) => (
  <InfoCardWrapper className={className} size={size}>
    {data.map((card, i) => (
      <React.Fragment key={`${card.label}_${card.value}`}>
        {i % 2 === 1 && <InfoCardDivider />}
        <InfoCardBody {...card} />
      </React.Fragment>
    ))}
  </InfoCardWrapper>
)

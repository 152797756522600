import React from 'react'

import { BuyPlatform } from 'shared/features/Buy/constants'
import { ReactComponent as ps4Icon } from 'shared/assets/icons/platforms/ps4.svg'
import { ReactComponent as pcIcon } from 'shared/assets/icons/platforms/pc-mac.svg'

type Props = React.SVGProps<SVGSVGElement> & {
  platform: BuyPlatform
}

const icons: Record<
  typeof BuyPlatform[keyof typeof BuyPlatform],
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  pc: pcIcon,
  ps4: ps4Icon,
}

export const PlatformIcon: React.FC<Props> = ({ platform, ...rest }) => {
  const Icon = icons[platform]
  return <Icon {...rest} />
}

/* eslint-disable import/no-duplicates */
import { formatRelative, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'

const pattern = 'd MMM yyyy, H:mm'

const formatRelativeLocale = {
  yesterday: 'вчера, H:mm',
  today: 'сегодня, H:mm',
  lastWeek: pattern,
  nextWeek: pattern,
  tomorrow: pattern,
  other: pattern,
}

const mapFormatRelative = (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token]

const locale = {
  locale: { ...ru, formatRelative: mapFormatRelative, formatRelativeLocale },
}

export default (value: string): string => {
  return formatRelative(parseISO(value), new Date(), locale)
    .replace('сент', 'сен')
    .replace('нояб', 'ноя')
    .replace(/\./, '')
}

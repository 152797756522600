import React from 'react'
import cn from 'clsx'

import capitalize from 'shared/utils/capitalize'

import styles from './InfoCard.module.css'
import { InfoCardSize } from './constants'

type Props = {
  className?: string
  size: InfoCardSize
}

export const InfoCardWrapper: React.FC<Props> = ({ children, className, size }) => {
  const wrapperCn = cn(styles.card, className, styles[`card_size${capitalize(size)}`])
  return <div className={wrapperCn}>{children}</div>
}

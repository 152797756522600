import React from 'react'
import cn from 'clsx'

import styles from './FutCoin.module.css'

interface FutCoinProps {
  className?: string
  disabled?: boolean
  onClick?(): void
}

const FutCoin: React.FC<FutCoinProps> = ({ className, disabled = false, onClick }): React.ReactElement => (
  <div
    className={cn(
      className,
      {
        [styles.futCoin_disabled]: disabled,
      },
      styles.futCoin,
    )}
    onClick={onClick}
    tabIndex={0}
    role="button"
    aria-label="coin"
  />
)

export default FutCoin

import React from 'react'
import cn from 'clsx'

import { ReactComponent as Ps4Icon } from 'shared/assets/icons/platforms/ps4.svg'
import { ReactComponent as XboxIcon } from 'shared/assets/icons/platforms/xbox.svg'
import capitalize from 'shared/utils/capitalize'

import Button, { ButtonColor, ButtonSize } from '../Button'
import { ButtonProps } from '../Button/types'

import styles from './ButtonConsole.module.css'

type Props = ButtonProps

export const ButtonConsole: React.FC<Props> = ({
  size = ButtonSize.m,
  color = ButtonColor.primary,
  outline = true,
  ...rest
}) => {
  const iconCn = cn(styles.icon, styles[`size${capitalize(size)}__icon`])
  const dividerCn = cn(styles.divider, styles[`divider_${color}`])

  return (
    <Button size={size} color={color} outline={outline} {...rest}>
      <div className={styles.content}>
        <div className={styles.platform}>
          <Ps4Icon className={iconCn} />
          <span>Ps</span>
        </div>
        <span className={dividerCn} />
        <div className={styles.platform}>
          <XboxIcon className={iconCn} />
          <span>Xbox</span>
        </div>
      </div>
    </Button>
  )
}

import React from 'react'
import cn from 'clsx'

import { Align } from 'shared/constants'

import styles from './Chip.module.css'

enum ChipColors {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
}

interface ChipProps {
  children: React.ReactElement | React.ReactChildren | React.ReactNode | string | number
  color?: keyof typeof ChipColors
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
  icon?: React.ReactElement | React.ReactChildren | React.ReactNode
  className?: string
  tabIndex?: number
  selected?: boolean
  align?: keyof typeof Align
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void
  onIconClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void
}

const Chip: React.FC<ChipProps> = ({
  children,
  color = ChipColors.default,
  component: Component = 'div',
  onClick,
  onIconClick,
  tabIndex,
  selected = false,
  align = Align.center,
  className,
  icon,
}): React.ReactElement => (
  <Component
    className={cn(
      styles.chip,
      {
        [styles.chip_primary]: color === ChipColors.primary,
        [styles.chip_secondary]: color === ChipColors.secondary,
        [styles.chip_selected]: selected,
        [styles.chip_center]: align === Align.center,
        [styles.chip_left]: align === Align.left,
        [styles.chip_right]: align === Align.right,
        [styles.chip_justify]: align === Align.justify,
        [styles.chip_inherit]: align === Align.inherit,
      },
      className,
    )}
    onClick={onClick}
    onKeyDown={undefined}
    role="button"
    tabIndex={tabIndex}
  >
    {icon && (
      <span className={styles.chip__icon} onClick={onIconClick} role="button" tabIndex={-1}>
        {icon}
      </span>
    )}
    <span className={styles.chip__label}>{children}</span>
  </Component>
)

export default Chip

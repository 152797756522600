import React, { useMemo, memo } from 'react'
import { range } from 'ramda'
import cn from 'clsx'

import Chip from 'shared/components/Chip'
import { ReactComponent as ChevronLeftIcon } from 'shared/assets/icons/chevron_left.svg'
import { ReactComponent as ChevronRightIcon } from 'shared/assets/icons/chevron_right.svg'
import { ReactComponent as ChevronLeftDoubleIcon } from 'shared/assets/icons/chevron_left_double.svg'
import { ReactComponent as ChevronRightDoubleIcon } from 'shared/assets/icons/chevron_right_double.svg'
import { PaginationParams, PaginationType } from 'shared/types'

import styles from './Pagination.module.css'

export interface PaginationProps extends PaginationParams {
  neighbours?: number
  className?: string
  onChange(page: number, size: number): void
  paginationType?: PaginationType
  children?: React.ReactElement | React.ReactNode | string | Array<React.ReactElement | React.ReactNode | string>
}

const Pagination: React.FC<PaginationProps> = ({
  number: page = 0,
  size = 0,
  totalPages = 0,
  neighbours = 2,
  className,
  onChange,
  paginationType = PaginationType.UNDER,
  children,
}): React.ReactElement => {
  const lastPage: number = useMemo((): number => totalPages - 1, [totalPages])

  const isDisabledPrev: boolean = useMemo((): boolean => page <= 0, [page])

  const isDisabledNext: boolean = useMemo((): boolean => page >= lastPage, [lastPage, page])

  const isVisibleLastPage: boolean = useMemo((): boolean => totalPages - page > 4, [totalPages, page])

  const isVisibleFirstPage: boolean = useMemo((): boolean => page > 3, [page])

  const pages: Array<number> = useMemo(() => {
    const totalNumbers = neighbours * 2 + 1
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const leftBound = page - neighbours
      const rightBound = page + neighbours + 1
      const beforeLastPage = totalPages

      const startPage = leftBound > 1 ? leftBound : 0
      const endPage = rightBound < lastPage ? rightBound : beforeLastPage

      const pgs = range(startPage, endPage)

      const pagesCount = pgs.length
      const singleSpillOffset = totalNumbers - pagesCount

      const leftSpill = startPage > 0
      const rightSpill = endPage < beforeLastPage

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage)
        return [...extraPages, ...pgs]
      }

      if (!leftSpill && rightSpill) {
        const extraPages = range(endPage, endPage + singleSpillOffset)
        return [...pgs, ...extraPages]
      }

      return pgs
    }

    return range(0, totalPages)
  }, [neighbours, totalPages, page, lastPage])

  const handleClick = (next: number) => (_event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (next !== page && onChange) {
      onChange(next, size)
    }
  }

  const handleClickPrev = (): void => {
    if (page > 0 && onChange) {
      onChange(page - 1, size)
    }
  }

  const handleClickNext = (): void => {
    if (page + 1 <= lastPage && onChange) {
      onChange(page + 1, size)
    }
  }

  const handleClickPrevJump = (): void => {
    if (onChange) {
      onChange(0, size)
    }
  }

  const handleClickNextJump = (): void => {
    if (onChange) {
      onChange(totalPages - 1, size)
    }
  }

  const arrowBtnClassName = cn(styles.button, styles.buttonArrow)

  const paginationAroundComponent = (
    <div className={styles.pagination_around}>
      <button
        className={cn(arrowBtnClassName, {
          [styles.buttonArrow_disabled]: isDisabledPrev,
        })}
        onClick={handleClickPrev}
        disabled={isDisabledPrev}
      >
        <ChevronLeftIcon />
      </button>
      {children}
      <button
        className={cn(arrowBtnClassName, {
          [styles.buttonArrow_disabled]: isDisabledNext,
        })}
        onClick={handleClickNext}
        disabled={isDisabledNext}
      >
        <ChevronRightIcon />
      </button>
    </div>
  )

  const paginationUnderComponent = (
    <div className={cn(styles.pagination, className)}>
      <div className={styles.pagination__pages}>
        <button
          className={cn(arrowBtnClassName, {
            [styles.buttonArrow_disabled]: isDisabledPrev,
          })}
          onClick={handleClickPrevJump}
          disabled={isDisabledPrev}
        >
          <ChevronLeftDoubleIcon />
        </button>

        <button
          className={cn(arrowBtnClassName, {
            [styles.buttonArrow_disabled]: isDisabledPrev,
          })}
          onClick={handleClickPrev}
          disabled={isDisabledPrev}
        >
          <ChevronLeftIcon />
        </button>
        {isVisibleFirstPage && (
          <>
            <Chip className={cn(styles.button, styles.button_first)} onClick={handleClick(0)} component="button">
              1
            </Chip>
            <Chip className={cn(styles.button, styles.button_dots)} component="span">
              ...
            </Chip>
          </>
        )}

        {pages.map(
          (p: number): React.ReactElement => {
            const selected = page === p

            return (
              <Chip
                className={cn(styles.button, {
                  [styles.button_selected]: selected,
                })}
                key={p}
                onClick={handleClick(p)}
                selected={selected}
                component="button"
              >
                {p + 1}
              </Chip>
            )
          },
        )}

        {isVisibleLastPage && (
          <>
            <Chip className={cn(styles.button, styles.button_dots)} component="span">
              ...
            </Chip>

            <Chip className={cn(styles.button, styles.button_last)} onClick={handleClick(lastPage)} component="button">
              {totalPages}
            </Chip>
          </>
        )}

        <button
          className={cn(arrowBtnClassName, {
            [styles.buttonArrow_disabled]: isDisabledNext,
          })}
          onClick={handleClickNext}
          disabled={isDisabledNext}
        >
          <ChevronRightIcon />
        </button>

        <button
          className={cn(arrowBtnClassName, {
            [styles.buttonArrow_disabled]: isDisabledNext,
          })}
          onClick={handleClickNextJump}
          disabled={isDisabledNext}
        >
          <ChevronRightDoubleIcon />
        </button>
      </div>
    </div>
  )

  return paginationType === PaginationType.UNDER ? paginationUnderComponent : paginationAroundComponent
}

export default memo(Pagination)
